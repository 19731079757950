<template>
  <unauthorized
    :authorized="$store.getters.can('view-brokered-settlements')"
    message="Unauthorized to view Lots"
  >
    <base-title
      title="Settlements List"
    >
      <template v-slot:subtitle>
        <span class="text-caption">
          Shows lots with Settlements and with a status of Tolling or Brokered
        </span>
      </template>
    </base-title>
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchAdvancedSets"
    >
      <date-range-input
        :start-date="createdStartDate"
        :end-date="createdEndDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="createdStartDate = $event"
        @alterEndDate="createdEndDate = $event"
      />
      <date-range-input
        :start-date="shipStartDate"
        :end-date="shipEndDate"
        start-date-label="Ship Date From"
        end-date-label="Ship Date To"
        @alterStartDate="shipStartDate = $event"
        @alterEndDate="shipEndDate = $event"
      />
      <date-range-input
        :start-date="valueStartDate"
        :end-date="valueEndDate"
        start-date-label="Value Date From"
        end-date-label="Value Date To"
        @alterStartDate="valueStartDate = $event"
        @alterEndDate="valueEndDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Smelter Lot Id"
            v-model="smelterLotId"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            label="Notes"
            v-model="notes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Division"
            multiple
            v-model="divisionSet"
            :items="divisions"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Smelter"
            multiple
            v-model="smelterSet"
            :items="smelters"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <search-autocomplete
            v-model="accountManager"
            label="Account Manager"
            item-text="username"
            item-value="username"
            api-path="/api/users/"
            :params="{ fields: 'username', is_active: true }"
            :format-entry="x => Object.assign({ friendlyName: x.username }, x)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Statuses"
            multiple
            v-model="statusSet"
            :items="lotStatuses"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Entity"
            v-model="entity"
            :items="entities"
            item-text="name"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Material Types"
            v-model="typeSet"
            multiple
            :items="$store.getters['getLotTypes']"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:item.name="{ item }">
        <router-link-id
          :id="item.id"
          name="lot"
          :display="item.name"
        />
        <div
          v-if="item.parent_data"
          class="pl-2"
        >
          <v-chip
            outlined
            x-small
          >
            {{ item.parent_data.name }} - primary
          </v-chip>
        </div>
        <div
          v-if="item.children_data"
          class="pl-2 d-flex flex-column align-start"
        >
          <v-chip
            v-for="(child, index) in item.children_data"
            :key="index"
            outlined
            x-small
          >
            {{ child.name }} - sub
          </v-chip>
        </div>
      </template>
      <template v-slot:item.brokered_settlement="{ item }">
        <span v-if="item.brokered_settlement">
          {{ item.brokered_settlement.id }}
        </span>
      </template>
      <template v-slot:item.get_designation_display="{ item }">
        {{ item.get_designation_display }}
      </template>
      <template v-slot:item.get_status_display="{ item }">
        {{ item.get_status_display }}
      </template>
      <template v-slot:item.brokered_settlement_id="{ item }">
        <v-chip
          v-if="item.brokered_settlement_id"
          dark
          class="black--text lighten-2"
          color="success"
          target="_blank"
          small
          :to="{name: 'print-brokered-settlement', params: { id: item.brokered_settlement_id }}"
        >
          Settlement <v-icon
            small
            class="ml-1"
          >
            mdi-open-in-new
          </v-icon>
        </v-chip>
        <span
          v-else-if="item.shipment_summary_lot"
          class="text-caption ml-2"
        >
          Summary Avail.
        </span>
      </template>
      <template v-slot:item.shipment_summary_assay_date="{ item }">
        {{ item.shipment_summary_assay_date | formatDate | filterNA }}
      </template>
      <template v-slot:item.yards="{ item }">
        <div
          class="pl-2 d-flex flex-column align-start"
        >
          <div
            v-for="(yard, index) in item.yards"
            :key="index"
            class="text-caption"
          >
            {{ yard.name }}
          </div>
        </div>
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <div class="py-8" />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { fetchEntities } from '@/requests/entities'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'LotList',
  components: {
    SearchAutocomplete,
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'lot-settlement-list',

      headers: [
        { text: 'Lot', value: 'name' },
        { text: 'Account Manager', value: 'account_manager__username' },
        { text: 'Entity', value: 'entity_code' },
        { text: 'Status', value: 'get_status_display' },
        { text: 'Designation', value: 'get_designation_display' },
        // Buyer / Manager
        { text: 'Yards', value: 'yards' },
        { text: 'Assay date', value: 'shipment_summary_assay_date' },
        { text: 'Settlement', value: 'brokered_settlement_id' }
      ],

      ...this.chipPropagation([
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Notes', prop: 'notes', type: String, query: 'notes__icontains' },
        { name: 'SmelterLotId', prop: 'smelterLotId', type: String, query: 'smelter_lot_id__icontains' },

        { name: 'Material Type', prop: 'typeSet', type: Array, query: 'lot_type__in' },

        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'division__code__in' },
        { name: 'Smelters', prop: 'smelterSet', type: Array, query: 'smelter__name__in' },
        { name: 'Account Manager', prop: 'accountManager', type: String, query: 'account_manager__username' },
        { name: 'Status', prop: 'statusSet', type: Array, query: 'status__in' },
        { name: 'Entity', prop: 'entity', type: String, query: 'entity__code' },
        { name: 'Start Ship Date', prop: 'shipStartDate', type: String, query: 'ship_date__gte' },
        { name: 'End Ship Date', prop: 'shipEndDate', type: String, query: 'ship_date__lte' },
        { name: 'Start Create Date', prop: 'createdStartDate', type: String, query: 'created_at__gte' },
        { name: 'End Create Date', prop: 'createdEndDate', type: String, query: 'created_at__lte' },
        { name: 'Start Value Date', prop: 'valueStartDate', type: String, query: 'shipment_summary_lot__value_date__gte' },
        { name: 'End Value Date', prop: 'valueEndDate', type: String, query: 'shipment_summary_lot__value_date__lte' }
      ]),

      divisions: [],
      smelters: [],
      entities: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      lotStatuses: 'lotStore/lotStatuses'
    })
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        'id',
        'name',
        'entity_code',
        'get_designation_display',
        'shipment_summary_lot',
        'brokered_settlement_id',
        // Manager
        // 'created_by',
        'yards',
        // assay date
        'get_status_display',
        // Meta Data
        'children_data',
        'shipment_summary_assay_date',
        'account_manager__username'
      ].join(',')

      // Custom flag that gets lots with a settlement or marked as Brokered / Tolling
      params.expects_settlement = 1
      axios.get('/api/lots/', { params }).then(this.setResponseResults)
    },
    fetchAdvancedSets () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name,code').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.code }))
        })
      }
      if (this.smelters.length === 0) {
        axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
          this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.name }))
        })
      }
      if (this.entities.length === 0) {
        fetchEntities().then(response => {
          if (response.data) this.entities = response.data
        })
      }
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
