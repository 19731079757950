<template>
  <unauthorized
    :authorized="$store.getters.can('view-advances')"
    message="Not Authorized to view Advances"
  >
    <app-loading-indicator v-if="loading" />
    <div v-if="!loading && advance">
      <url-breadcrumbs
        :items="[
          ['Advance List', { name: 'advance-list' }],
          ['Advance #' + advance.id, { name: 'advance-view', param: { id: advance.id } }],
        ]"
      />
      <v-row>
        <v-col cols="12">
          <base-title v-if="!loading">
            {{ `Advance ID #${advance.id}` }}
            <v-chip
              outlined
              small
              :color="statusColor"
            >
              {{ advance.status_text }}
            </v-chip>
          </base-title>
          <div class="d-flex justify-end mx-2">
            <info-tooltip
              v-if="advance.status !== 'VD'"
            >
              <template v-slot:wrap-me>
                <v-btn
                  :disabled="!isOwner"
                  color="error"
                  text
                  @click="markVoid"
                >
                  Mark Void
                </v-btn>
              </template>
              can only be voided by creator
            </info-tooltip>
          </div>
          <div>
            <div class="d-flex flex-column flex-md-row">
              <v-card
                class="ma-2"
                width="400"
              >
                <v-card-title>
                  Payment Details
                </v-card-title>
                <v-card-text>
                  <fx-tag
                    class="font-weight-bold"
                    :fx="advance.amount_fx"
                    :value="advance.amount"
                    :cur="advance.currency"
                  />
                  <br>
                  <div class="mt-4">
                    <strong>Entered As</strong>
                    <br>
                    {{ valueFormatted }}
                    <br>
                    {{ advance.payment_date | formatDate }}
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                class="ma-2"
                width="400"
              >
                <v-card-title>
                  Related Entities
                </v-card-title>
                <v-card-text>
                  <h3 class="subtitle-2">
                    Lot
                  </h3>
                  {{ advance.lot_name }}
                  <h3 class="subtitle-2">
                    Created For
                  </h3>
                  {{ advance.created_for_username }}
                </v-card-text>
              </v-card>
              <v-card
                class="ma-2"
                width="400"
              >
                <v-card-title>
                  Details
                </v-card-title>
                <v-card-text>
                  <h3 class="subtitle-2">
                    Pro Forma
                  </h3>
                  <a
                    v-if="advance.pro_forma_upload"
                    :href="advance.pro_forma_upload"
                    target="_blank"
                  >
                    Download Pro Forma Document
                  </a>
                  <div v-else>
                    --
                  </div>
                  <h3 class="subtitle-2">
                    Created By
                  </h3>
                  {{ advance.created_by_username }}
                  <h3 class="subtitle-2">
                    Created On
                  </h3>
                  {{ advance.created_at | formatDate }}
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      Not Found
    </div>
  </unauthorized>
</template>

<script>

import axios from 'axios'

export default {
  data: () => ({
    loading: true,
    advance: null
  }),
  computed: {
    statusColor () {
      const value = this.advance ? this.advance.status : ''
      switch (value) {
        case 'OP':
          return 'success'
        case 'ST':
          return 'primary'
        case 'VD':
          return 'error'
        default:
          return ''
      }
    },
    valueFormatted () {
      if (!this.advance) return 'N/A'
      const currency = new Intl.NumberFormat('en-US',
        { style: 'currency', currency: this.advance.currency }
      )
      return currency.format(this.advance.amount)
    },
    isOwner () {
      if (this.$auth.user && this.advance) {
        return this.$auth.user.id === this.advance?.created_by
      }
      return false
    }
  },
  methods: {
    markVoid () {
      if (confirm('Are you sure you would like to void this entry?')) {
        axios.patch(`/api/advances/${this.$route.params.id}/`, { status: 'VD' }).then(() => {
          this.initialize()
        }).catch(err => {
          this.$store.commit('setSnackbarError', err)
        })
      }
    },
    initialize () {
      return axios.get(`/api/advances/${this.$route.params.id}/`).then(response => {
        this.advance = response.data
      })
    }
  },
  mounted () {
    this.initialize().finally(() => {
      this.loading = false
    })
  }
}
</script>
