var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-advances'),"message":"Not Authorized to view Advances"}},[_c('base-title',{attrs:{"title":"Advance List"}}),_c('search-filters',{attrs:{"chips":_vm.chips},on:{"clearChip":_vm.clearChipProp,"clearFields":_vm.clearFields,"submitSearch":_vm.routePage},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"ID","type":"number"},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"label":"Status","clearable":"","items":_vm.$store.getters['getAdvanceStatuses'],"item-text":"text","item-value":"value"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',[_c('search-autocomplete',{attrs:{"label":"Related Lot","item-text":"friendlyName","item-value":"name","api-path":"/api/lots/","params":{ fields: 'name', ordering: '-id' },"format-entry":function (x) { return Object.assign({}, { friendlyName: x.name }, x); }},model:{value:(_vm.lot),callback:function ($$v) {_vm.lot=$$v},expression:"lot"}})],1)],1)],1),(_vm.pagination)?_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.resultList,"server-items-length":_vm.totalCount,"options":_vm.pagination,"footer-props":{ 'items-per-page-options': [20] },"hide-default-footer":""},on:{"update:options":function($event){_vm.pagination=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.id,"name":"advance-view"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(item.status_text)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrencyV")(item.amount,item.currency))+" ")]}},{key:"item.amount_fx",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrencyV")(item.amount_fx))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.payment_date))+" ")]}}])}),(_vm.pagination)?_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),(_vm.$store.getters.can('create-advances'))?_c('text-fab',{attrs:{"bottom":"","right":"","fixed":"","icon-text":"mdi-plus","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'advance-create' })}}},[_vm._v(" Create Advance ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }